const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://w59pj5ohj2.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://ts4y2uia8k.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.10.2',
    HOSTNAME: 'https://company.dev.exploration.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.exploration.forwoodsafety.com',
    WEBSOCKET: 'wss://qud3gskc58.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;